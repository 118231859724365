import React, { useEffect, useState, useCallback, useMemo } from 'react'
import TelegramAPI from '#/telegram'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'
import Slider from './components/Slider/Slider'
import { BalanceCoinIcon, PremiumMiningIcon } from '#/assets/icons'
import { useUser } from '#/context/userContext'
import { modes } from '#/data/data'
import { useNavigate } from 'react-router-dom'
import styles from './ShopPage.module.css'
import { formatNumber } from '#/services/helpers'
import BoosterCard from './components/BoosterCard'
import Notification from '#/components/alerts/Notification'
import useNotification from '#/hooks/useNotification'
import PremiumModal from '#/components/modals/PremiumModal'
import BoostersModal from '#/components/modals/Boosters'
import DungerModal from './components/DungerModal'

function ShopPage() {
  const [openBoostersModal, setOpenBoostersModal] = useState(false)
  const [selectedBooster, setSelectedBooster] = useState(0)
  const { notification, isVisible, showNotification } = useNotification()

  // Context
  const { user, cards, boosters, buyVideocard } = useUser()

  // Selected videocard
  const [modeIndex, setModeIndex] = useState(0)

  // Items count manipulate
  const [itemsCount, setItemsCount] = useState(1)

  const handleAddCount = useCallback(() => {
    const haveBooster = user && user.boosters.find((item) => item.item_id === 0)
    if (haveBooster) {
      setItemsCount((prevCount) => prevCount + 1)
    } else {
      return showNotification('error', 'To buy more than one card, purchase a booster "Multiple GPU"', 3000)
    }
  }, [user, itemsCount])

  const handleMinusCount = useCallback(() => {
    setItemsCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount))
  }, [])

  // Back button
  const navigate = useNavigate()
  const handleBack = () => {
    navigate('/')
  }
  useEffect(() => {
    TelegramAPI.ShowBackButton()
    TelegramAPI.OnBackButtonClick(handleBack)
  }, [])

  // Background style
  const blockStyle = useMemo(() => modes[modeIndex].shopBlockStyle, [modeIndex])

  // Calculate total price
  const totalCoinPrice = useMemo(() => {
    let summ = itemsCount * parseFloat(cards[modeIndex]?.priceCoin)

    return summ
  }, [itemsCount, cards, modeIndex])

  const [dungerModal, setDungerModal] = useState(false)
  const [isDungerVisible, setIsDungerVisible] = useState(false)

  const handleButtonPaymentClick = async () => {
    const existBooster = user.boosters.some((item) => item.item_id === 0)

    if (!existBooster && user && user.cards?.length > 0) {
      setDungerModal(true)
      setTimeout(() => setIsDungerVisible(true), 10)
    } else {
      handlePayment('coin')
    }
  }

  const acceptDungerPurchase = () => {
    TelegramAPI.Vibrate('heavy')
    closeModal()
    handlePayment('coin')
  }

  const declineDungerPurchase = () => {
    TelegramAPI.Vibrate('heavy')
    closeModal()
  }

  const closeModal = () => {
    setIsDungerVisible(false)
    setTimeout(() => setDungerModal(false), 500)
  }

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains(styles.dungerModalOverlay)) {
      closeModal()
    }
  }

  // Payment
  const handlePayment = async (type) => {
    if (type === 'coin') {
      const totalPrice = totalCoinPrice

      if (totalPrice > user?.user?.balance) {
        return showNotification('error', 'Insufficient balance for this purchase.', 3000)
      }

      try {
        const response = await buyVideocard(modeIndex, itemsCount)
        if (response.success) {
          showNotification('success', 'Successfully purchased.', 3000)
        } else {
          showNotification('error', response.message, 3000)
        }
      } catch (error) {
        showNotification('error', 'An error occurred during the purchase process.', 3000)
      }
    }
  }

  const existingMultiGPU = (user) => user?.boosters.some((item) => item.item_id === 0)

  // Premium Modal
  const [isOpPremium, setIsOpPremium] = useState(false)

  return (
    <div
      className={styles.container}
      style={{
        overflowY: 'scroll',
      }}>
      <Slider
        cards={cards}
        modeIndex={modeIndex}
        setModeIndex={setModeIndex}
        blockStyle={blockStyle}
        count={itemsCount}
      />

      <div className={styles.summaryInfo}>
        <div className={styles.countLine}>
          <div className={styles.count__header}>Quantity</div>

          <div className={styles.countController}>
            <div
              className={`${styles.countButton} ${styles.countButton_minus} ${
                existingMultiGPU(user) && itemsCount > 1 && styles.active
              }`}
              onClick={handleMinusCount}>
              -
            </div>
            <div className={styles.count}>{itemsCount}</div>
            <div
              className={`${styles.countButton} ${existingMultiGPU(user) && styles.active}`}
              onClick={handleAddCount}>
              +
            </div>
          </div>
        </div>
        <div className={`text_style_description`} style={{ margin: '6px 0 20px' }}>
          If you don't have the Multiple GPU booster, purchasing a new card will replace your previous one, and 50% of
          the cost of the old card will be automatically refunded to your balance. To be able to own multiple cards at
          once, get the Multiple GPU booster.
        </div>
      </div>

      <div className={styles.boostersContainer}>
        <div className={styles.count__header}>Permanent boosters</div>
        <div className={styles.boosters}>
          {boosters &&
            boosters.map((booster, index) => (
              <BoosterCard
                item={booster}
                key={index}
                handleClick={() => {
                  setOpenBoostersModal(true)
                  setSelectedBooster(booster.id)
                }}
              />
            ))}
        </div>
      </div>

      <div
        className={`${styles.boostersContainer} ${styles.premiumSection}`}
        onClick={() => {
          TelegramAPI.Vibrate('heavy')
          setIsOpPremium(true)
        }}>
        <div className={styles.left}>
          <div className={styles.premiumIconHolder}>
            <PremiumMiningIcon className={styles.shadow} height={40} />
            <PremiumMiningIcon className={styles.shadow} height={40} />
            <PremiumMiningIcon className={styles.shadow} height={40} />
            <PremiumMiningIcon className={styles.shadow} height={40} />
            <PremiumMiningIcon className={styles.shadow} height={40} />
            <PremiumMiningIcon className={styles.premiumIcon} height={40} />
          </div>
          <div className={styles.description}>
            <h3>Mining Premium</h3>
            <p>Purchase mining premium for boost your income by 25%</p>
          </div>
        </div>
      </div>

      <div className={styles.paymentContainer}>
        <div className={styles.modalBalance}>Your balance {user && formatNumber(user?.user.balance)}</div>
        <div className={styles.payment}>
          <div
            className={styles.paymentButton_coin}
            onClick={() => handleButtonPaymentClick('coin')}
            style={totalCoinPrice > user?.user.balance ? { opacity: 0.4 } : {}}>
            <BalanceCoinIcon className={styles.coin} /> Pay {formatNumber(totalCoinPrice)}
          </div>
        </div>
      </div>

      

      {dungerModal && (
        <DungerModal
          acceptDungerPurchase={acceptDungerPurchase}
          declineDungerPurchase={declineDungerPurchase}
          handleOverlayClick={handleOverlayClick}
          isDungerVisible={isDungerVisible}
        />
      )}

      {isOpPremium && <PremiumModal onClose={() => setIsOpPremium(false)} />}
      {openBoostersModal && (
        <BoostersModal
          closeModal={() => setOpenBoostersModal(false)}
          boosters={boosters}
          openBooster={selectedBooster}
        />
      )}
      <BottomNavigation activeTab="shop" />
      <Notification notification={notification} isVisible={isVisible} />
    </div>
  )
}

export default ShopPage
