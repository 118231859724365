import React from 'react'
import styles from './styles.module.css'

const StatsCard = ({title, value}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.dots}></div>
      <div className={styles.value}>{value}</div>
    </div>
  )
}

export default StatsCard