import React from 'react'
import styles from './styles.module.css'

const CustomSkeleton = ({ width, height, percents = false }) => {
  return (
    <div className={styles.skeleton} style={{ width: `${width}${percents ? '%' : 'px'}`, height: `${height}${percents ? '%' : 'px'}` }}></div>
  )
}

export default CustomSkeleton
