import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement)

const LineChart = ({ dataPoints }) => {
  const timestamps = dataPoints.map((point) => new Date(point[0] * 1000).toLocaleString())
  const values = dataPoints.map((point) => point[1])

  const data = {
    labels: timestamps,
    datasets: [
      {
        label: 'Value Over Time',
        data: values,
        borderColor: 'rgba(173, 255, 47, 0.8)',
        borderWidth: 1,
        fill: false,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: { display: false },
      y: { display: false },
    },
    elements: {
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
  }

  return <Line data={data} options={options} />
}

export default LineChart