import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './BottomNavigation.module.css'
import { HomeIcon, FriendsIcon, TasksIcon } from '../../../assets/icons'
import TelegramAPI from '../../../telegram'

const TopIcon = ({ height = 18, ...props }) => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" color="currentColor" {...props}>
    <path
      d="M12 17C10.3264 17 8.86971 18.265 8.11766 20.1312C7.75846 21.0225 8.27389 22 8.95877 22H15.0412C15.7261 22 16.2415 21.0225 15.8823 20.1312C15.1303 18.265 13.6736 17 12 17Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.5 5H19.7022C20.9031 5 21.5035 5 21.8168 5.37736C22.13 5.75472 21.9998 6.32113 21.7393 7.45395L21.3485 9.15307C20.7609 11.7086 18.6109 13.6088 16 14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 5H4.29779C3.09692 5 2.49649 5 2.18324 5.37736C1.86999 5.75472 2.00024 6.32113 2.26075 7.45395L2.65148 9.15307C3.23914 11.7086 5.38912 13.6088 8 14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17C15.0208 17 17.565 12.3379 18.3297 5.99089C18.5412 4.23558 18.647 3.35793 18.0868 2.67896C17.5267 2 16.6223 2 14.8134 2H9.18658C7.37775 2 6.47333 2 5.91317 2.67896C5.35301 3.35793 5.45875 4.23558 5.67025 5.99089C6.435 12.3379 8.97923 17 12 17Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

const WalletIcon = ({ height = 18, ...props }) => (
  <svg width={height} height={height} viewBox="0 0 24 24" fill="none" color="currentColor" {...props}>
    <path
      d="M16 14C16 14.8284 16.6716 15.5 17.5 15.5C18.3284 15.5 19 14.8284 19 14C19 13.1716 18.3284 12.5 17.5 12.5C16.6716 12.5 16 13.1716 16 14Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M18.9 8C18.9656 7.67689 19 7.34247 19 7C19 4.23858 16.7614 2 14 2C11.2386 2 9 4.23858 9 7C9 7.34247 9.03443 7.67689 9.10002 8"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M7 8.00005H20C21.1046 8.00005 22 8.89548 22 10V20C22 21.1046 21.1046 22 20 22H4.00004C2.89548 22 2.00005 21.1046 2.00004 20.0001L2.00001 6.00001C2 4.89543 2.89543 4 4.00001 4H10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const BottomNavigation = React.memo(({ activeTab }) => {
  const navigate = useNavigate()

  const handleNavigate = useCallback(
    (tabKey, route) => {
      if (activeTab !== tabKey) {
        TelegramAPI.Vibrate('heavy')
        navigate(route)
      }
    },
    [activeTab, navigate]
  )

  const tabs = useMemo(
    () => [
      { tabKey: 'tasks', label: 'Tasks', icon: <TasksIcon height={18} />, route: '/tasks' },
      { tabKey: 'friends', label: 'Friends', icon: <FriendsIcon height={18} />, route: '/friends' },
      { tabKey: 'home', label: 'Mine', icon: <HomeIcon height={18} />, route: '/' },
      { tabKey: 'rating', label: 'Top', icon: <TopIcon height={18} />, route: '/rating' },
      { tabKey: 'profile', label: 'Profile', icon: <WalletIcon height={18} />, route: '/profile' },
    ],
    []
  )

  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        {tabs.map(({ tabKey, label, icon, route }) => (
          <div
            key={tabKey}
            className={`${styles.button} ${activeTab === tabKey ? styles.active : ''}`}
            onClick={() => handleNavigate(tabKey, route)}>
            <div className={styles.button__icon}>{icon}</div>
            <div className={styles.button__text}>{label}</div>
          </div>
        ))}
      </div>
    </div>
  )
})

export default BottomNavigation
