import styles from './style.module.css'
import StakingCard from '../StakingCard'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import InputWithButtons from '#/components/forms/InputWithButtons'
import TelegramAPI from '#/telegram'
import Modal from '#/components/Modal/Modal'
import { useUser } from '#/context/userContext'
import { useState } from 'react'
import { formatNumber } from '#/services/helpers'

const StakingModal = ({ onClose, stakeOption }) => {
  const { user } = useUser()
  const [stakeAmount, setStakeAmount] = useState(0)

  const setPercentValue = async (percent) => {
    TelegramAPI.Vibrate('heavy')
    const balance = user?.user?.balance
    if (balance) {
      let stakeAmount = balance * percent
      stakeAmount = Math.floor((stakeAmount * 100) / 100)
      setStakeAmount(stakeAmount)
    } else {
      setStakeAmount(0)
    }
  }

  const stakeButtonOptions = () => {
    const balance = user?.user?.balance;
  
    if (stakeAmount === 0) {
      return { title: 'Enter an amount', onClick: () => {}, style: { opacity: 0.6 } };
    }
  
    if (balance < 1) {
      return { title: 'Insufficient balance', onClick: () => {}, style: { opacity: 0.6 } };
    }
  
    if (balance < stakeAmount) {
      return { title: 'Balance too low for staking', onClick: () => {}, style: { opacity: 0.6 } };
    }
  
    return {
      title: `Stake ${formatNumber(stakeAmount, 'balance')} $MineX`,
      onClick: () => {},
      style: { opacity: 1 },
    };
  };

  return (
    <Modal title={`Stake ${stakeOption.coin}`} onClose={onClose} background={'#1C1C1E'}>
      <StakingCard
        isModal={true}
        containerStyle={styles.stakingData}
        stakeOption={stakeOption}
        receiveAmount={stakeAmount}
      />
      <InputWithButtons
        containerStyle={styles.stakingInput}
        value={stakeAmount}
        setValue={(e) => {
          if (!e.target.value) {
            setStakeAmount(0)
          } else {
            setStakeAmount(parseFloat(e.target.value))
          }
        }}
        options={[
          { title: '100%', onClick: () => setPercentValue(1) },
          { title: '50%', onClick: () => setPercentValue(0.5) },
          { title: '25%', onClick: () => setPercentValue(0.25) },
        ]}
      />
      <div className={styles.modalBalance}>Your balance {formatNumber(user?.user?.balance)}</div>
      <PrimaryButton {...stakeButtonOptions()}>{stakeButtonOptions().title}</PrimaryButton>
    </Modal>
  )
}

export default StakingModal
