import React from "react";
import styles from './styles.module.css'


export default function SettingsPage () {
    return (
        <div>
            Settings
        </div>
    )
}