import React, { createContext, useContext, useEffect, useState, useRef } from 'react'
import API from '../api/api'
import useNotification from '#/hooks/useNotification'
import Notification from '#/components/alerts/Notification'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  // /** @type {User} */
  const [user, setUser] = useState()
  const [cards, setCards] = useState([])
  const [boosters, setBoosters] = useState([])
  const [refferals, setRefferals] = useState({})
  // const [boosters, setBoosters] = useState([
  //   {
  //     id: 0,
  //     type: 'booster',
  //     name: 'Multiple GPU',
  //     description:
  //       'Unlock the ability to mine cryptocurrencies with increased power. With this booster, you can own an unlimited number of GPUs. Applies to all your GPUs.',
  //     priceCoin: 2500,
  //     priceTon: 0.05,
  //   },
  //   {
  //     id: 1,
  //     type: 'booster',
  //     name: 'PowerFlow',
  //     description:
  //       'With this booster, your GPUs will operate 24/7. It automatically restarts your devices if they go offline. Applies to all your GPUs.',
  //     priceCoin: 1500,
  //     priceTon: 0.06,
  //   },
  // ])

  // const [user, setUser] = useState({
  //   user: {
  //     user_id: 1942355581,
  //     username: 'MaxDevDes',
  //     name: 'Maksim | Develop & Design',
  //     referal_id: null,
  //     registration_date: '2024-10-16T12:46:13.000Z',
  //     balance: '50.00000000',
  //     photo_id: 'AgACAgIAAxUAAWcr1T_ecyo--KDoaPR7nl62gc1TAAKd5zEbtRsgSRQ7mmnCy8AlAQADAgADYQADNgQ',
  //     memo: '29514184',
  //     is_premium: 0,
  //   },
  //   card: 0,
  //   referal_link: 'https://t.me/MineXton_bot?start=r_1942355581',
  //   miningInfo: {
  //     success: true,
  //     miningActive: false,
  //     timerMilis: 48197724,
  //     canClaimReward: false,
  //     rewardsInfo: [
  //       {
  //         purchaseId: 386071,
  //         videocardId: 0,
  //         name: 'Calcuminer',
  //         ghs: 22,
  //         reward: '1.74452072',
  //         lastMiningDate: '2024-11-13 21:05:33',
  //         status: 'off',
  //         coinsPerHour: 0.2,
  //         purchaseDate: '2024-10-27 13:37:00',
  //       },
  //     ],
  //   },
  //   totalMined: '633.89142405',
  //   cards: [
  //     {
  //       purchase_id: 386071,
  //       user_id: 1942355581,
  //       item_id: 0,
  //       type: 'videocard',
  //       balance: '1.74452072',
  //       last_activate: '2024-11-13T18:05:33.000Z',
  //       status: 'off',
  //       purchase_date: '2024-10-27T10:37:00.000Z',
  //     },
  //   ],
  //   boosters: [],
  // })

  // const [refferals, setRefferals] = useState({
  //   totalRefferals: 20,
  //   reward: 500.1205752,
  //   refRewards: 10.94186032,
  //   refferals: [
  //     {
  //       name: 'Дмитрий',
  //       reward: 250.08509986,
  //       photo_id: 'AgACAgIAAxUAAWcWwKZzqO2l9mqNmz9uUz9OyI7GAAKypzEbubxrFSg02Y28B5e1AQADAgADYQADNgQ',
  //       refRewards: 0.0,
  //     },
  //     {
  //       name: 'Ivan',
  //       reward: 250.03547534,
  //       photo_id: 'AgACAgIAAxUAAWcWv3RVZBfmp41djwqiQpQvGeTIAALR2DEbCCCwS4hGmN31XWX1AQADAgADYQADNgQ',
  //       refRewards: 10.94186032,
  //     },
  //     {
  //       name: 'Ivan',
  //       reward: 250.03547534,
  //       photo_id: 'AgACAgIAAxUAAWcWv3RVZBfmp41djwqiQpQvGeTIAALR2DEbCCCwS4hGmN31XWX1AQADAgADYQADNgQ',
  //       refRewards: 10.94186032,
  //     },
  //     {
  //       name: 'Ivan',
  //       reward: 250.03547534,
  //       photo_id: 'AgACAgIAAxUAAWcWv3RVZBfmp41djwqiQpQvGeTIAALR2DEbCCCwS4hGmN31XWX1AQADAgADYQADNgQ',
  //       refRewards: 10.94186032,
  //     },
  //     {
  //       name: 'Andrew',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAVdrweDOz5t_UHwHRYzcTz4_SQACet0xGzjNWEo1P4Hx9G2iAAEBAAMCAANhAAM2BA',
  //       refRewards: 0.0,
  //     },
  //     { name: 'Николай', reward: 0.0, photo_id: null, refRewards: 0.0 },
  //     {
  //       name: 'ㅤㅤ',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAVdftPl6CaONeAUn6GDigKqwSAACsdkxG_NiMUo3KnWhelqBUQEAAwIAA2EAAzYE',
  //       refRewards: 0.0,
  //     },
  //     {
  //       name: 'Vasiliy @ hidden',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAVQC9N-nLmHJRJyb0I_sH_LgwQACX9wxG0UwCEqq_p-Sp1GA6wEAAwIAA2EAAzYE',
  //       refRewards: 0.0,
  //     },
  //     { name: ')', reward: 0.0, photo_id: null, refRewards: 0.0 },
  //     { name: 'Сергей', reward: 0.0, photo_id: null, refRewards: 0.0 },
  //     {
  //       name: 'Никита',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAVG5MK3XsCKCaAcgLMDXaNOrJQACvKcxG0AiaQeKateg_FtLdAEAAwIAA2EAAzYE',
  //       refRewards: 0.0,
  //     },
  //     { name: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', reward: 0.0, photo_id: null, refRewards: 0.0 },
  //     {
  //       name: 'Alexander',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAVcahXvVK40HNNpnAoY36fZtHwAC5qcxGxRdIA9jbXKjT8p1MwEAAwIAA2EAAzYE',
  //       refRewards: 0.0,
  //     },
  //     {
  //       name: 'Pavlidol',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAVdnJUXB_yjylAYryvHiHabQswACrqcxG_UvEg1HE49SKyjLEQEAAwIAA2EAAzYE',
  //       refRewards: 0.0,
  //     },
  //     {
  //       name: 'Дмитрий',
  //       reward: 250.08509986,
  //       photo_id: 'AgACAgIAAxUAAWcWwKZzqO2l9mqNmz9uUz9OyI7GAAKypzEbubxrFSg02Y28B5e1AQADAgADYQADNgQ',
  //       refRewards: 0.0,
  //     },
  //     {
  //       name: 'Punk 🦴',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAU_3SVy9IgvhuDtrOpRdDfD_twACsKcxGxCNowaHVTqkDed1TwEAAwIAA2EAAzYE',
  //       refRewards: 0.0,
  //     },
  //     {
  //       name: 'Борис',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAVduRBgSMRMguwEvhrFuLgu_vgAC4OoxG2Td0ElwwA-odEApqwEAAwIAA2EAAzYE',
  //       refRewards: 0.0,
  //     },
  //     { name: 'Sergio', reward: 0.0, photo_id: null, refRewards: 0.0 },
  //     {
  //       name: 'Павел 🌱SEED',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcAAVbW9D8a4nZfPWzHFhwoly1OWgACq6cxG4Hohi1UL8EZmh5ORgEAAwIAA2EAAzYE',
  //       refRewards: 0.0,
  //     },
  //     {
  //       name: 'Vlad',
  //       reward: 0.0,
  //       photo_id: 'AgACAgIAAxUAAWcBbdjsgalJy2_Mo6u_gQ8Ho2qjAAK3pzEb8YjqIi05ROggN3N5AQADAgADYQADNgQ',
  //       refRewards: 0.0,
  //     },
  //   ],
  // })

  // const [cards, setCards] = useState([
  //   {
  //     id: 0,
  //     type: 'videocard',
  //     name: 'Calcuminer',
  //     ghs: 22,
  //     coinsPerHour: 0.2,
  //     priceCoin: 500,
  //   },
  //   {
  //     id: 1,
  //     type: 'videocard',
  //     name: 'Basic miner',
  //     ghs: 95,
  //     coinsPerHour: 0.85,
  //     priceCoin: 2000,
  //   },
  //   {
  //     id: 2,
  //     type: 'videocard',
  //     name: 'Pro hash',
  //     ghs: 270,
  //     coinsPerHour: 2.3,
  //     priceCoin: 5000,
  //   },
  //   {
  //     id: 3,
  //     type: 'videocard',
  //     name: 'Block Breaker',
  //     ghs: 730,
  //     coinsPerHour: 6.3,
  //     priceCoin: 11500,
  //   },
  //   {
  //     id: 4,
  //     type: 'videocard',
  //     name: 'Elite power',
  //     ghs: 2100,
  //     coinsPerHour: 17,
  //     priceCoin: 24900,
  //   },
  //   {
  //     id: 5,
  //     type: 'videocard',
  //     name: 'Quantum core',
  //     ghs: 6050,
  //     coinsPerHour: 52,
  //     priceCoin: 69400,
  //   },
  // ])

  const [rewardsInfo, setRewardsInfo] = useState([])
  const [tasks, setTasks] = useState([])

  const [error, setError] = useState(false)
  const [groupedCards, setGroupedCards] = useState([])
  const [settings, setSettings] = useState([])
  const [withdraws, setWithdrawhs] = useState([])
  const [timerMilis, setTimerMilis] = useState(0)
  const [storedDeposits, setStoredDeposits] = useState([])
  const { notification, isVisible, showNotification } = useNotification()

  const timerInterval = useRef(null)

  const updateUserInfo = async () => {
    const response = await API.user.getUserInfo()
    if (response) {
      setUser(response)
      setRewardsInfo(response.miningInfo?.rewardsInfo || [])
      setTimerMilis(response.miningInfo?.timerMilis || 0)
    } else {
      setError(true)
    }
  }

  // Функция для форматирования времени в часы:минуты:секунды
  const formatTime = (ms) => {
    let totalSeconds = Math.floor(ms / 1000)
    let hours = Math.floor(totalSeconds / 3600)
    let minutes = Math.floor((totalSeconds % 3600) / 60)
    let seconds = totalSeconds % 60

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  // Функция для получения форматированного времени таймера
  const getFormattedTimer = () => formatTime(timerMilis)

  useEffect(() => {
    // Очищаем предыдущий интервал, если он существует
    if (timerInterval.current) {
      clearInterval(timerInterval.current)
    }

    // Запускаем новый интервал, если таймер больше нуля
    if (timerMilis > 0) {
      timerInterval.current = setInterval(() => {
        setTimerMilis((prevTimer) => {
          if (prevTimer > 1000) {
            return prevTimer - 1000
          } else {
            clearInterval(timerInterval.current)
            return 0
          }
        })
      }, 1000)
    }

    return () => clearInterval(timerInterval.current)
  }, [timerMilis])

  // Рассчитать награду в секунду
  const calculateRewardPerSecond = (coinsPerHour) => {
    return coinsPerHour / 3600
  }

  useEffect(() => {
    if (rewardsInfo.length > 0) {
      groupCardsByCount()

      if (user?.miningInfo?.miningActive) {
        const interval = setInterval(() => {
          setRewardsInfo((prevRewards) =>
            prevRewards.map((card) => ({
              ...card,
              reward: (parseFloat(card.reward) + calculateRewardPerSecond(card.coinsPerHour)).toFixed(8),
            }))
          )
        }, 1000)

        return () => clearInterval(interval)
      }

      return
    }
  }, [rewardsInfo])

  // Группировка карт по количеству
  const groupCardsByCount = () => {
    const grouped = {}
    rewardsInfo.forEach((card) => {
      if (!grouped[card.videocardId]) {
        grouped[card.videocardId] = {
          ...card,
          count: 1,
          reward: parseFloat(card.reward),
        }
      } else {
        grouped[card.videocardId].count += 1
        grouped[card.videocardId].reward += parseFloat(card.reward)
      }
    })
    setGroupedCards(Object.values(grouped))
  }

  // Tasks
  const getTasks = async () => {
    if (user) {
      const result = await API.tasks.getTasks()
      if (result) {
        setTasks(result.data)
      }
    }
  }

  const completeTask = async (task_id) => {
    try {
      const result = await API.tasks.completeTask(task_id)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const verifyTask = async (task_id) => {
    try {
      const result = await API.tasks.verifyTaskCompletion(task_id)
      return result
    } catch (e) {
      console.log(e)
    }
  }

  // Mining
  const claimMiningRewards = async () => {
    try {
      const result = await API.mining.claimReward()
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const continueMining = async () => {
    try {
      const result = await API.mining.continueMining()
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  // Shop
  const getShopCards = async () => {
    try {
      const result = await API.shop.getAviableVideocards()
      setCards(result)
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const getShopBoosters = async () => {
    try {
      if (user) {
        const result = await API.shop.getAviableBoosters()
        const purchasedBoosterIds = user.boosters.map((booster) => booster.item_id)
        const updatedBoosters = result.map((booster) => ({
          ...booster,
          buyed: purchasedBoosterIds.includes(booster.id),
        }))

        setBoosters(updatedBoosters)
        return updatedBoosters
      }
      return
    } catch (e) {
      console.log(e)
    }
  }

  const buyBooster = async (item_id, payment = 'coin', wallet = '') => {
    try {
      const result = await API.shop.buyBooster(item_id, payment, wallet)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const buyVideocard = async (item_id, count, payment = 'coin', wallet = '') => {
    try {
      const result = await API.shop.buyVideocard(item_id, count, payment, wallet)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const getSettings = async () => {
    try {
      const response = await API.system.getSettings()
      if (response.success) {
        setSettings(response.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const withdraw = async (value, wallet) => {
    try {
      const response = await API.user.withdraw(value, wallet)
      if (response.success) {
        getWithdrawHistory()
        updateUserInfo()
      }
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const getWithdrawHistory = async () => {
    try {
      const response = await API.user.withdraws()
      if (response.success) {
        setWithdrawhs(response.data)
      }
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const checkTransactionAfterDeposit = async (deposits) => {
    try {
      if (deposits) {
        setStoredDeposits(deposits) // Обновляем состояние
      }

      const result = await API.deposit.getDepositHistory()

      if (result && result.success) {
        setStoredDeposits((prevDeposits) => {
          const newDeposits = result.deposits.filter(
            (deposit) => !prevDeposits.some((existingDeposit) => existingDeposit.deposit_id === deposit.deposit_id)
          )

          if (newDeposits.length > 0) {
            updateUserInfo()
            showNotification('success', 'Balance successfully topped up', 3000)
            return [...prevDeposits, ...newDeposits] // Возвращаем обновлённое состояние
          }

          return prevDeposits // Если нет новых депозитов, возвращаем текущее состояние
        })

        return true
      } else {
        // Если запрос не успешен, ждем 10 секунд и повторяем
        await new Promise((resolve) => setTimeout(resolve, 10000))
        return checkTransactionAfterDeposit() // Рекурсивный вызов
      }
    } catch (e) {
      console.log('Error during deposit check:', e)
    }
  }

  const fetchRefferals = async () => {
    try {
      const response = await API.user.refferals()
      if (response.success) {
        setRefferals(response.stats)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    updateUserInfo()
  }, [])

  useEffect(() => {
    if (user) {
      getShopBoosters()
      getShopCards()
      getSettings()
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        getTasks,
        verifyTask,
        completeTask,
        claimMiningRewards,
        getShopBoosters,
        getShopCards,
        tasks,
        cards,
        boosters,
        buyBooster,
        buyVideocard,
        updateUserInfo,
        continueMining,
        error,
        rewardsInfo,
        groupedCards,
        settings,
        getWithdrawHistory,
        withdraws,
        withdraw,
        timerMilis,
        getFormattedTimer,
        checkTransactionAfterDeposit,
        storedDeposits,
        setStoredDeposits,
        fetchRefferals,
        refferals,
      }}>
      <>
        {children}
        <Notification notification={notification} isVisible={isVisible} />
      </>
    </UserContext.Provider>
  )
}

export const useUser = () => {
  return useContext(UserContext)
}
