import React, { useState } from 'react'
import styles from './styles.module.css'
import { BalanceCoinIcon, StakingIcon } from '#/assets/icons'
import StakingCard from './components/StakingCard'
import Modal from '#/components/Modal/Modal'
import TelegramAPI from '#/telegram'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import StakingModal from './components/StakingModal'
import { Link } from 'react-router-dom'

export default function StakingPage() {
  const [isStakingOpen, setIsStakingOpen] = useState(false)
  const handleSelectStaking = () => {
    TelegramAPI.Vibrate('heavy')
    setIsStakingOpen(true)
  }
  return (
    <div className={styles.main}>
      {/* Header */}
      <div className={styles.header}>
        <div className={styles.title}>
          <StakingIcon /> <div>Staking</div>
        </div>
        <div className={styles.description}>Increase earnings by staking your mined coins in the staking</div>
      </div>
      {/* Staking Card */}
      <div className={styles.stakingCards}>
        <StakingCard
          handleClick={() => handleSelectStaking()}
          isModal={false}
          stakeOption={{ coin: 'MineX', arp: 172, days: 30 }}
        />
      </div>

      <Link to={'/profile'} className={styles.backLink}>
        <PrimaryButton>Back</PrimaryButton>
      </Link>

      {/* Staking modal */}
      {isStakingOpen && (
        <StakingModal onClose={() => setIsStakingOpen(false)} stakeOption={{ coin: 'MineX', arp: 172, days: 30 }} />
      )}
    </div>
  )
}
