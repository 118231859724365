import React from 'react'
import styles from './styles.module.css'

const StatsGroup = ({ children, title }) => {
  return (
    <div className={styles.group}>
      <div className={styles.title}>{title}</div>
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default StatsGroup
