import React, { useState, useMemo, useCallback } from 'react'
import { SERVER_LINK } from '#/data/serverLink'
import { formatNumber } from '#/services/helpers'
import { BalanceCoinIcon } from '#/assets/icons'
import styles from './style.module.css'
import UserIcon from '#/components/avatars/default'

const UserCard = React.memo(({ item, filter, index, type = 'raitng' }) => {
  const [loading, setLoading] = useState(true)
  const [hideImage, setHideImage] = useState(false)

  const handleImageLoad = () => {
    setLoading(false)
  }

  const ghtToTHS = useCallback((number) => {
    return parseFloat(number) / 1000
  }, [])

  const displayValue = useMemo(() => {
    const balance = (
      <div className={`${styles.user__balance} ${index + 1 <= 3 ? styles.balanceTop : ''}`}>
        <BalanceCoinIcon width={14} height={14} /> {formatNumber(parseFloat(item.balance))}
      </div>
    )

    const ghs = (
      <div className={styles.user__ghs}>
        {ghtToTHS(item.total_ghs).toFixed(2)}
        <span>TH/s</span>
      </div>
    )

    return filter === 'balance' ? (
      <>
        {balance}
        {ghs}
      </>
    ) : (
      <>
        {ghs}
        {balance}
      </>
    )
  }, [filter, item.balance, item.total_ghs, ghtToTHS])

  return (
    <div
      className={`${styles.user} 
        ${index === 0 && styles.user_gold}
        ${index === 1 && styles.user_silver}
        ${index === 2 && styles.user_bronze} `}>
      <div className={`${styles.user_index} ${index + 1 <= 3 ? styles.prize : ''}`}>
        {index + 1 === 1 && '1'}
        {index + 1 === 2 && '2'}
        {index + 1 === 3 && '3'}
        {index + 1 > 3 ? type === 'personal' ? `#${index}` : `#${index + 1}` : ''}
      </div>
      <UserIcon 
        photoId={item?.photo_id} 
        name={item?.name} 
        width={40} 
        // additionalStyles={{ container: {}, icon: {}, loader: {} }} 
      />
      <div className={styles.user__info}>
        <div className={`${styles.user__name} ${index + 1 <= 3 ? styles.nameTop : ''}`}>{item.name}</div>
        <div className={styles.user__dataLine}>{displayValue}</div>
      </div>
    </div>
  )
})

export default UserCard
