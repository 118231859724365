import { LightingIcon, RocketIcon } from '../assets/icons'

import card0 from '../assets/cards/card1.png'
import card1 from '../assets/cards/card_v1.png'
import card2 from '../assets/cards/card_v2.png'
import card3 from '../assets/cards/card_v3.png'
import card4 from '../assets/cards/card_v4.png'
import card5 from '../assets/cards/card_max.png'

import calcGif from '../assets/gifs/calc.gif'
import card1Gif from '../assets/gifs/card_v1.gif'
import card2Gif from '../assets/gifs/card_v2.gif'
import card3Gif from '../assets/gifs/card_v3.gif'
import card4Gif from '../assets/gifs/card_v4.gif'
import card5Gif from '../assets/gifs/card_max.gif'

import rocket from '../assets/gifs/rocket.gif'
import lighting from '../assets/gifs/booster.gif'


import loader from '../assets/gifs/loader.gif'

export const loaderGif = loader

export const modes = {
  0: {
    navigation: {
      prev: '#fc0105',
      next: '#00FF73',
    },
    shopBlockStyle: {
      filter: 'blur(100px) saturate(3)',
      background: '#d7a240',
    },
  },

  1: {
    navigation: {
      prev: '#d7a240',
      next: '#CAB50F',
    },
    shopBlockStyle: {
      filter: 'blur(100px) saturate(3)',
      background: '#00FF73',
    },
  },

  2: {
    navigation: {
      prev: '#00FF73',
      next: '#f700fa',
    },
    shopBlockStyle: {
      filter: 'blur(100px) saturate(3)',
      background: '#CAB50F',
    },
  },
  3: {
    navigation: {
      prev: '#CAB50F',
      next: '#f700fa',
    },
    shopBlockStyle: {
      filter: 'blur(100px) saturate(3)',
      background: '#0797FE',
    },
  },
  4: {
    navigation: {
      prev: '#0797FE',
      next: '#fc0105',
    },
    shopBlockStyle: {
      filter: 'blur(100px) saturate(3)',
      background: 'linear-gradient(180deg, #f700fa 0%, #01fcf6 100%)',
    },
  },
  5: {
    navigation: {
      prev: '#f700fa',
      next: '#d7a240',
    },
    shopBlockStyle: {
      filter: 'blur(100px) saturate(3)',
      background: 'linear-gradient(175deg, #ffe300 0%, #fc01fc 83%, #fc0105 100%)',
    },
  },
  null: {
    navigation: {
      prev: '#803CAB',
      next: '#1B957C',
    },
    shopBlockStyle: {
      filter: 'blur(100px) saturate(3)',
      background: '#d7a240',
    },
  },
}

export const boosterIcons = [
  { item_id: 0, icon: <RocketIcon height="32" width="32" />, gif: rocket },
  { item_id: 1, icon: <LightingIcon height="32" width="32" />, gif: lighting },
]

export const cardsImages = [
  { id: 0, image: card0, gif: calcGif },
  { id: 1, image: card1, gif: card1Gif },
  { id: 2, image: card2, gif: card2Gif },
  { id: 3, image: card3, gif: card3Gif },
  { id: 4, image: card4, gif: card4Gif },
  { id: 5, image: card5, gif: card5Gif },
]
