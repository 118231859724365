import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import API from '#/api/api';

const RatingContext = createContext();

export function useRating() {
  return useContext(RatingContext);
}

export function RatingProvider({ children }) {
  const [ratingDay, setRatingDay] = useState({ data: [], lastUpdated: null, userPosition: null, userStats: null });
  const [ratingWeek, setRatingWeek] = useState({ data: [], lastUpdated: null, userPosition: null, userStats: null });
  const [loading, setLoading] = useState(false);

  const fetchRating = useCallback(async (ratingType) => {
    setLoading(true);
    try {
      const users = await API.user.getUserTop('balance', ratingType);
      const now = new Date();
      if (ratingType === 'daily') {
        setRatingDay({ data: users.topUsers, lastUpdated: now, userPosition: users.userPosition, userStats: users.userStats });
      } else {
        setRatingWeek({ data: users.topUsers, lastUpdated: now, userPosition: users.userPosition, userStats: users.userStats });
      }
    } catch (error) {
      console.error('Failed to fetch rating:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getRating = useCallback(
    (ratingType) => {
      const rating = ratingType === 'daily' ? ratingDay : ratingWeek;
      const now = new Date();
      if (!rating.lastUpdated || (now - new Date(rating.lastUpdated)) / 1000 > 300) {
        fetchRating(ratingType);
      }
      return rating;
    },
    [ratingDay, ratingWeek, fetchRating]
  );

  const getUserPosition = useCallback(() => {
    if (!ratingDay.userPosition) {
      fetchRating('daily');
    }
    return ratingDay.userPosition;
  }, [ratingDay, fetchRating]);

  return (
    <RatingContext.Provider value={{ getRating, getUserPosition, loading }}>
      {children}
    </RatingContext.Provider>
  );
}
