import React from 'react'
import styles from './BoosterCard.module.css'
import { boosterIcons } from '#/data/data'
import TelegramAPI from '#/telegram'

const BoosterCard = ({ item, handleClick }) => {
  const getButtonTitle = () => {
    return item.buyed ? 'Bought' : 'Purchase'
  }

  const onClick = () => {
    if (!item.buyed) {
      TelegramAPI.Vibrate('heavy')
      handleClick()
    }
  }

  return (
    <div className={styles.boster}>
      <div className={styles.boosterIconContainer}>
        <img src={boosterIcons[item.id].gif} alt="" className={styles.boosterIcon} />
      </div>
      <div>
        <div className={styles.boosterHeader}>
          <div className={styles.boosterTitle}>{item.name}</div>
          <button className={`${styles.boosterButton} ${styles.active} ${item.buyed && styles.buyed}`} onClick={onClick}>
            {getButtonTitle()}
          </button>
        </div>
        <div className={styles.boosterDescription}>{item.description}</div>
      </div>
    </div>
  )
}

export default BoosterCard
