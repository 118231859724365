import React from 'react'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'

const MenuItem = ({ title, description, icon, titleStyles, descriptionStyles, containerStyles, linkTo }) => {
  return (
    <Link className={`${styles.container} ${containerStyles}`} to={linkTo ? linkTo : ''}>
      <div className={`${styles.titleContainer} ${titleStyles}`}>
        {icon && icon}
        <div className={`${styles.title} ${titleStyles}`}>{title}</div>
      </div>
      <div className={`${styles.description} ${descriptionStyles}`}>{description}</div>
    </Link>
  )
}

export default MenuItem