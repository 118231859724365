import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'

import './App.css'
import TasksPage from './pages/TasksPage'
import HomePage from './pages/HomePage'
import FriendsPage from './pages/FriendsPage'

import TelegramAPI from './telegram'
import ShopPage from './pages/ShopPage'
import { UserProvider } from './context/userContext'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import WalletPage from './pages/WalletPage'
import RatingPage from './pages/RatingPage'
import { TonClientProvider } from './context/ton-client-context'
import ImageLoader from './components/loaders/ImageLoader'
import { loaderGif } from './data/data'

import MinexStatsPage from './pages/profileGroup/MinexStatsPage'
import StatisticPage from './pages/profileGroup/StatisticPage'
import SettingsPage from './pages/profileGroup/SettingsPage'
import StakingPage from './pages/profileGroup/StakingPage'
import ProfilePage from './pages/profileGroup/ProfilePage'

import { RatingProvider } from './context/rating-context'

function AnimatedRoutes() {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route path="/" element={<HomePage />} />
      <Route path="/friends" element={<FriendsPage />} />
      <Route path="/tasks" element={<TasksPage />} />
      <Route path="/shop" element={<ShopPage />} />
      <Route path="/wallet" element={<WalletPage />} />
      <Route path="/rating" element={<RatingPage />} />

      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/staking" element={<StakingPage />} />
      <Route path="/setting" element={<SettingsPage />} />
      <Route path="/statistic" element={<StatisticPage />} />
      <Route path="/minex-stats" element={<MinexStatsPage />} />
    </Routes>
  )
}

function App() {
  // const [showLoader, setShowLoader] = useState(true)

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowLoader(false)
  //   }, 1500)

  //   return () => clearTimeout(timer)
  // }, [])

  TelegramAPI.expand()
  TelegramAPI.SetBackgroundColor('#000')
  TelegramAPI.SetHeaderColor('#000')
  const manifestUrl = 'https://minexton.com/tonconnect-manifest.json'

  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <TonClientProvider>
        <RatingProvider>
          <UserProvider>
            <Router>
              {/* {showLoader && <ImageLoader />} */}
              <AnimatedRoutes />
            </Router>
          </UserProvider>
        </RatingProvider>
      </TonClientProvider>
    </TonConnectUIProvider>
    // <div className='m_message'>
    //   <img src={loaderGif} className='mGif' />
    //   <div className='mHeader'>Maintenance Notice</div>
    //   <div className='mDesc'>
    //     Maintenance is expected to take 30-60 minutes. Your funds will remain secure in your accounts during this time.
    //   </div>
    // </div>
  )
}

export default App
