import React, { useCallback, useState, useEffect } from 'react'
import Modal from '#/components/Modal/Modal'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import TabsNavBar from '#/components/navigation/TabsNavBar'
import { useUser } from '#/context/userContext'
import styles from './DepositModal.module.css'
import useNotification from '#/hooks/useNotification'
import Notification from '#/components/alerts/Notification'
import TransactionCard from '#/components/cards/TransactionCard'
import { getSettingValue } from '#/services/getSettingValue'
import API from '#/api/api'
import coin from '#/assets/coin.png'
import TelegramAPI from '#/telegram'
import { Address } from '@ton/core'
import { useTonAddress, useTonConnectModal } from '@tonconnect/ui-react'
import { JettonMaster } from '@ton/ton'
import { JettonWallet } from '#/wrappers/JettonWallet'
import { useTonConnect } from '#/hooks/useTonConnect'
import TextInput from '#/components/forms/TextInput/TextInput'
import { InfoIcon } from '#/assets/icons'
import InputWithButtons from '#/components/forms/InputWithButtons'

const tabs = [
  { name: 'deposit', title: 'Deposit' },
  { name: 'history', title: 'History' },
]

export default function DepositModal({ isOpen, setIsOpen }) {
  const { user, settings, updateUserInfo, setStoredDeposits, checkTransactionAfterDeposit } = useUser()
  const { notification, isVisible, showNotification } = useNotification()

  const [activeTab, setActiveTab] = useState('deposit')
  const [depositHistory, setDepositHistory] = useState([])
  const [invoiceWalletAddress, setInvoiceWalletAddress] = useState(null)
  const [jettonMasterAddress, setJettonMasterAddress] = useState(null)
  const [tokenNano, setTokenNano] = useState(null)
  const [feeConst, setFeeConst] = useState(null)
  const [depositAmount, setDepositAmount] = useState(0)
  const { open } = useTonConnectModal()
  const { sender, walletAddress, tonClient } = useTonConnect()
  const userFriendlyAddress = useTonAddress()

  const [connectedWalletBalance, setConnectedWalletBalance] = useState(0)

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (activeTab === 'history') {
      fetchDepositHistory()
    }
  }, [activeTab])

  useEffect(() => {
    if (isOpen) {
      fetchDepositHistory()
      loadSettings()
    }
  }, [isOpen])

  const loadSettings = async () => {
    try {
      const INVOICE_WALLET_ADDRESS = getSettingValue({ settings: settings, title: 'wallet_adress' })
      const USDT_MASTER_ADDRESS = getSettingValue({ settings: settings, title: 'jetton_master' })
      const FEE_CONST = getSettingValue({ settings: settings, title: 'fee' })
      const TOKEN_NANO = getSettingValue({ settings: settings, title: 'nano' })

      setInvoiceWalletAddress(INVOICE_WALLET_ADDRESS)
      setJettonMasterAddress(USDT_MASTER_ADDRESS)
      setFeeConst(FEE_CONST)
      setTokenNano(TOKEN_NANO)
    } catch (error) {
      console.error('Error loading settings:', error)
    }
  }

  const fetchBalance = async (primary = false) => {
    if (walletAddress.toString() && jettonMasterAddress) {
      const userBalance = await API.settings.getWalletUSDTbalance(walletAddress.toString(), jettonMasterAddress)
      const parsedusdtbalance = userBalance?.jetton_wallets[0]?.balance
      setConnectedWalletBalance(parseFloat(parsedusdtbalance))
    }
  }

  useEffect(() => {
    fetchBalance()
  }, [user, jettonMasterAddress])

  const fetchDepositHistory = async () => {
    try {
      const history = await API.deposit.getDepositHistory()
      setDepositHistory(history.deposits)
    } catch (error) {
      console.error('Error fetching deposit history:', error)
      showNotification('error', 'Failed to fetch deposit history', 3000)
    }
  }

  // Функция проверки депозита
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const handleCheckDeposit = async () => {
    try {
      const result = await API.deposit.getDepositHistory()

      if (result && result.success) {
        const newDeposits = result.deposits.filter(
          (deposit) => !depositHistory.some((existingDeposit) => existingDeposit.deposit_id === deposit.deposit_id)
        )

        // Искусственная задержка
        await delay(3000)

        if (newDeposits.length > 0) {
          depositHistory.push(...newDeposits)
          showNotification('success', 'Balance successfully topped up', 3000)
          updateUserInfo()
        } else {
          showNotification('error', 'Transaction not found, please try again in 1 minute', 3000)
        }
      }
    } catch (error) {
      console.error('Error checking deposits:', error)
    }
  }

  const setPercentValue = async (percent) => {
    TelegramAPI.Vibrate('heavy')
    if (connectedWalletBalance) {
      let walletBalance = connectedWalletBalance / Math.pow(10, tokenNano)
      let depositAmount = walletBalance * percent
      depositAmount = Math.floor((depositAmount * 100) / 100)
      setDepositAmount(depositAmount)
    } else {
      setDepositAmount(0)
    }
  }

  const handleCompletePayment = useCallback(
    async (amount) => {
      if (amount < 1) {
        return
      }
      try {
        if (!tonClient || !walletAddress) return

        const jettonMaster = tonClient.open(JettonMaster.create(Address.parse(jettonMasterAddress)))
        const usersUsdtAddress = await jettonMaster.getWalletAddress(walletAddress)

        const jettonWallet = tonClient.open(JettonWallet.createFromAddress(usersUsdtAddress))
        const userBalance = await API.settings.getWalletUSDTbalance(walletAddress.toString(), jettonMasterAddress)
        const parsedusdtbalance = userBalance?.jetton_wallets[0]?.balance

        if (!parsedusdtbalance || parsedusdtbalance < amount * Math.pow(10, tokenNano)) {
          return showNotification(
            'error',
            'The linked TON wallet associated with your account does not have sufficient funds to complete the transaction. Please top up your wallet before proceeding.',
            3000
          )
        }

        await jettonWallet.sendTransfer(sender, {
          fwdAmount: 1n,
          comment: user?.user?.memo,
          jettonAmount: Number(amount * Math.pow(10, tokenNano)),
          toAddress: Address.parse(invoiceWalletAddress),
          value: feeConst,
        })

        showNotification('success', 'Deposit accepted for processing', 3000)
        // await fetchBalance(true)
      } catch (error) {
        console.log('Error during transaction:', error)
      }
    },
    [tonClient, walletAddress, sender, user]
  )

  const depositButtonParams = useCallback(() => {
    const depositValue = parseFloat(depositAmount)
    if (!userFriendlyAddress) {
      return { text: 'Connect Wallet', action: open, style: {} }
    }

    if (depositValue < 1) {
      return { text: `Deposit`, action: () => {}, style: { opacity: 0.4 } }
    }

    return {
      text: `Deposit`,
      action: async () => {
        await handleCompletePayment(depositValue)
        setDepositAmount(0)
      },
      style: {},
    }
  }, [depositAmount])

  if (isOpen)
    return (
      <Modal title="Deposit" onClose={handleCloseModal} modalHeight={'100vh'}>
        <TabsNavBar activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
        {activeTab === 'deposit' && (
          <div className={styles.depositTab}>
            <div className={styles.deposit_main}>
              <div className={styles.depositTitle}>App balance top-up</div>

              <div className={styles.depositInput}>

                <InputWithButtons
                  value={depositAmount}
                  setValue={(e) => {
                    if (!e.target.value) {
                      setDepositAmount(0)
                    } else {
                      setDepositAmount(parseFloat(e.target.value))
                    }
                  }}
                  options={[
                    { value: '100%', onClick: () => setPercentValue(1) },
                    { value: '50%', onClick: () => setPercentValue(0.5) },
                    { value: '25%', onClick: () => setPercentValue(0.25) },
                  ]}
                />

                {/* <TextInput
                  label={'Amount'}
                  value={depositAmount}
                  setValue={}
                  type="float"
                /> */}
                <div className={styles.depositAmountButtons}>
                  <div onClick={() => setPercentValue(1)}>100%</div>
                  <div onClick={() => setPercentValue(0.5)}>50%</div>
                  <div onClick={() => setPercentValue(0.25)}>25%</div>
                </div>
              </div>

              <div className={styles.depositButtons}>
                <PrimaryButton style={depositButtonParams().style} onClick={depositButtonParams().action}>
                  {depositButtonParams().text}
                </PrimaryButton>
                <PrimaryButton className={styles.checkButton} onClick={handleCheckDeposit}>
                  Verify
                  <br />
                  transaction
                </PrimaryButton>
              </div>
              <div className={styles.infoAlert}>
                <InfoIcon height={72} className={styles.infoAlert__icon} />
                <div>
                  After completing a successful transaction in your wallet, wait a couple of minutes and then click the
                  "Verify Transaction" button to update your balance in the app
                </div>
              </div>
            </div>
            <div className={styles.buyBanner}>
              <div className={styles.buyTitle}>You're out of MineX? Buy some</div>
              <PrimaryButton
                className={styles.buyButton}
                onClick={async () => {
                  TelegramAPI.OpenLink('https://dedust.io/swap/TON/EQByn5rWMo254atRkD8DKHckK2FUIPYsEsU6osoyIK6M6Wt0')
                }}>
                <span>Buy $MineX</span>
              </PrimaryButton>
            </div>
          </div>
        )}
        {activeTab === 'history' && (
          <div className={styles.withdrawhs}>
            {depositHistory ? (
              depositHistory.map((item, index) => <TransactionCard key={index} item={item} type="deposit" />)
            ) : (
              <div>No deposit history available.</div>
            )}
          </div>
        )}
        <Notification notification={notification} isVisible={isVisible} />
      </Modal>
    )
}
