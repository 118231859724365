import React from 'react'
import styles from './styles.module.css'
import StatsCard from './components/StatsCard'
import { Link } from 'react-router-dom'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import { UserStats } from '#/assets/icons'
import StatsGroup from './components/StatsGroup'

export default function StatisticPage() {
  return (
    <div className={styles.main}>
      <div className={styles.titleBar}>
        <UserStats className={styles.minexDataIcon} />
        <div className={styles.title}>Personal data</div>
      </div>
      {/* <div>Mining</div> */}
      <div className={styles.stats}>
        <StatsGroup title={'Mining'}>
          <StatsCard title={'Total mined'} value={0} />
          <StatsCard title={'Mined From Videocards'} value={0} />
          <StatsCard title={'Bonuses From Premium'} value={0} />
          <StatsCard title={'Bonuses From Pool'} value={0} />
        </StatsGroup>

        <StatsGroup title={'Friends'}>
          <StatsCard title={'Total earned'} value={0} />
          <StatsCard title={'Total invited'} value={0} />
          <StatsCard title={'Active refferals'} value={0} />
        </StatsGroup>

        <StatsGroup title={'Staking'}>
          <StatsCard title={'Total staked'} value={0} />
          <StatsCard title={'Earned'} value={0} />
          <StatsCard title={'Staking now'} value={0} />
        </StatsGroup>
      </div>
      <Link to={'/profile'} className={styles.backLink}>
        <PrimaryButton>Back</PrimaryButton>
      </Link>
    </div>
  )
}
