import React, { useState } from 'react'
import { SERVER_LINK } from '#/data/serverLink'
import styles from './style.module.css'

const UserIcon = ({
  photoId,
  name,
  width = 40,
  height = 40,
  containerClass = '',
  iconClass = '',
  loaderClass = '',
  additionalStyles = {},
}) => {
  const [loading, setLoading] = useState(true)
  const [hideImage, setHideImage] = useState(false)

  const handleImageLoad = () => {
    setLoading(false)
  }

  return (
    <div className={`${styles.user__icon} ${containerClass}`} style={additionalStyles.container}>
      {photoId ? (
        <>
          {loading && (
            <div className={`${styles.spinnerContainer} ${loaderClass}`} style={additionalStyles.loader}>
              <div className={styles.spinner}></div>
            </div>
          )}
          <img
            src={`${SERVER_LINK}/user-photo/${photoId}`}
            alt=''
            className={`${styles.image} ${iconClass}`}
            style={{ ...additionalStyles.icon, opacity: hideImage ? 0 : 1, width, height }}
            onLoad={handleImageLoad}
            onError={() => setHideImage(true)}
            loading="lazy"
          />
        </>
      ) : (
        <div className={`${styles.defaultAvatar} ${iconClass}`} style={additionalStyles.defaultAvatar}>
          {name.slice(0, 1)}
        </div>
      )}
    </div>
  )
}

export default UserIcon
