import React from 'react'
import styles from './styles.module.css'

const DungerModal = ({isDungerVisible, handleOverlayClick, acceptDungerPurchase, declineDungerPurchase}) => {
  return (
    <div className={`${styles.dungerModalOverlay} ${isDungerVisible ? styles.show : ''}`} onClick={handleOverlayClick}>
      <div className={`${styles.dungerModal} ${isDungerVisible ? styles.show : styles.hide}`}>
        <div className={styles.dungerModal_content}>
          You don't have the 🚀 Multiple GPU booster, which allows you to own 2 or more GPUs at once. As a result, your
          old GPU will be replaced with the new one, and 50% of the old GPU's value will be credited back to your
          balance.
        </div>
        <div className={styles.dungerModal__buttons}>
          <div className={styles.dungerModal__buttons_accept} onClick={acceptDungerPurchase}>
            I know, still buy
          </div>
          <div className={styles.dungerModal__buttons_decline} onClick={declineDungerPurchase}>
            Got it, decline
          </div>
        </div>
      </div>
    </div>
  )
}

export default DungerModal
