import React, { useState } from 'react'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'
import UserIcon from '#/components/avatars/default'
import styles from './styles.module.css'

import { useUser } from '#/context/userContext'
import { PremiumIconBg, PremiumIconContent, SettingIcon, StakingIcon, StatsIcon, UserStats } from '#/assets/icons'
import MenuItem from './components/MenuItem'
import PremiumModal from '#/components/modals/PremiumModal'
import TelegramAPI from '#/telegram'
import { useRating } from '#/context/rating-context'

const data = {
  premium: '-',
  // premium: '12/31/2025',
}

export default function ProfilePage() {
  const [openPremiumModal, setOpenPremiumModal] = useState(false)
  const handlePremiumClick = () => {
    TelegramAPI.Vibrate('heavy')
    setOpenPremiumModal(true)
  }
  const { user } = useUser()
  const { getUserPosition } = useRating()
  const userPosition = getUserPosition()

  const ghs = () => {
    if (!user?.miningInfo?.rewardsInfo) {
      return 0
    }
    const ghs = user?.miningInfo?.rewardsInfo?.reduce((s, i) => (s = s + i.ghs), 0)
    if (ghs >= 1000) {
      return `${Math.floor(((ghs / 1000) * 100) / 100)} TH/s`
    }

    return `${ghs} GH/s`
  }

  if (user && user?.user) {
    return (
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.settingsLine}>
            {data.premium === '-' ? (
              <div className={styles.premiumPurchase} onClick={handlePremiumClick}>
                <div className={styles.premiumIcon}>
                  <PremiumIconBg className={styles.bg} />
                  <PremiumIconContent className={styles.content} />
                </div>
                <div className={styles.premiumDate}>Purchase premium</div>
              </div>
            ) : (
              <div className={styles.premiumData_container}>
                <div className={styles.premiumIcon}>
                  <PremiumIconBg className={styles.bg} />
                  <PremiumIconContent className={styles.content} />
                </div>
                <div className={styles.premiumDate}>Premium active until {data.premium}</div>
              </div>
            )}

            <SettingIcon className={styles.settingsIcon} />
          </div>

          <div className={styles.userInfo}>
            <div className={styles.userAvatarContainer}>
              <UserIcon
                photoId={user?.user?.photo_id}
                name={user?.user?.name}
                width={'25vw'}
                height={'25vw'}
                additionalStyles={{
                  container: { height: '25vw', width: '25vw' },
                  icon: { height: '25vw', width: '25vw', zIndex: 1 },
                  loader: { height: '25vw', width: '25vw' },
                }}
              />
              <div className={styles.shadow}></div>
            </div>
            <div className={styles.name}>{user.user.name}</div>
            <div className={styles.stats}>
              <div className={styles.hashRate}>{ghs()} </div>
              <div className={styles.position}>#{userPosition || 'Loading...'}</div>
            </div>
          </div>
        </div>

        <div className={styles.poolContainer}>
          <div className={styles.pool}>
            <div className={styles.avatar}>?</div>
            <div className={styles.title}>Choose mining pool</div>
          </div>
        </div>

        <div className={styles.menuContainer}>
          <MenuItem
            title={'Staking'}
            description={'Increase earnings by staking your mined coins in the staking'}
            containerStyles={styles.stakingMenuStyle}
            titleStyles={styles}
            linkTo={'/staking'}
            icon={<StakingIcon className={styles.stakingIcon} />}
          />
          <MenuItem
            title={'MineX data'}
            description={'Explore key statistics and stay one step ahead'}
            linkTo={'/minex-stats'}
            icon={<StatsIcon className={styles.minexDataIcon} />}
          />
          <MenuItem
            title={'Personal data'}
            description={'Access essential data and analyze your performance'}
            linkTo={'/statistic'}
            icon={<UserStats className={styles.personalDataIcon} />}
          />
        </div>

        {openPremiumModal && <PremiumModal onClose={() => setOpenPremiumModal(false)} />}
        <BottomNavigation activeTab={'profile'} />
      </div>
    )
  }
}
