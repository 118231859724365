import React, { useEffect, useState, useCallback, useMemo } from 'react'
import TabsNavBar from '#/components/navigation/TabsNavBar'
import styles from './RatingPage.module.css'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'
import diamond from '#/assets/diamond.gif'
import { FiltersIcon } from '#/assets/icons'
import TelegramAPI from '#/telegram'
import UserCard from './components/UserCard'
import { useRating } from '#/context/rating-context'

const tabs = [
  { name: 'day', title: 'Day' },
  { name: 'week', title: 'Week' },
]



export default function RatingPage() {
  const { getRating, loading } = useRating()
  const [filter, setFilter] = useState('balance')
  const [activeTab, setActiveTab] = useState('day')

  const {
    data: rating,
    userPosition,
    userStats,
  } = useMemo(() => {
    const ratingType = activeTab === 'day' ? 'daily' : 'weekly'
    return getRating(ratingType)
  }, [activeTab, getRating])

  const handleClickFilter = useCallback(() => {
    TelegramAPI.Vibrate('heavy')
    setFilter((prevFilter) => (prevFilter === 'balance' ? 'ghs' : 'balance'))
  }, [])

  const renderedUsers = useMemo(() => {
    return rating.map((user, index) => <UserCard key={user.id || index} item={user} index={index} filter={filter} />)
  }, [rating, filter])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={diamond} className={styles.gif} alt="Diamond" loading="lazy" />
        <div className={styles.pageHeader}>Top miners</div>
      </div>

      <TabsNavBar activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />

      <div className={styles.filtersButton} onClick={handleClickFilter}>
        <FiltersIcon additionalColor="#0097EC" /> {filter === 'balance' ? 'Balance' : 'TH/s'}
      </div>

      <div className={styles.usersTable}>
        {loading ? <div className={styles.loader}></div> : renderedUsers}
        {userStats && userPosition && userPosition > 100 && (
          <div className={styles.personCard}>
            <UserCard item={userStats} index={userPosition} filter={filter} type={'personal'} />
          </div>
        )}
      </div>

      <BottomNavigation activeTab="rating" />
    </div>
  )
}
