import TextInput from '../TextInput/TextInput'
import styles from './styles.module.css'

const InputWithButtons = ({ value, setValue, options, label = 'Amount', type = 'float', containerStyle }) => {
  return (
    <div className={`${styles.depositInput} ${containerStyle}`}>
      <TextInput label={label} value={value} setValue={(e) => setValue(e)} type={type} />
      <div className={styles.depositAmountButtons}>
        {options.map((item, index) => (
          <div key={index} onClick={item.onClick}>{item.title}</div>
        ))}
      </div>
    </div>
  )
}

export default InputWithButtons
