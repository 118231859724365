import { useState } from 'react'
import { PremiumIconOutline, PremiumIconBg, PremiumIconContent } from '#/assets/icons'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import styles from './PremiumModal.module.css'
import TelegramAPI from '#/telegram'
import { useTonConnectUI } from '@tonconnect/ui-react'
import { beginCell,toNano } from '@ton/ton'


const prices = [
  { term: '1 year', price: 25 },
  { term: '3 months', price: 7 },
  { term: '1 month', price: 3 },
]

const body = beginCell()
  .storeUint(0, 32) 
  .storeStringTail('Hello, TON!') 
  .endCell()

const myTransaction = {
  validUntil: Math.floor(Date.now() / 1000) + 360,
  messages: [
    {
      address: 'UQCQD6K8UhdhbNTprCCVay7i3tVxSycpaMVxamG2CmOrJ4o3',
      amount: toNano('0.05').toString(),
      payload: body.toBoc().toString('base64'), 
    },
  ],
}

export default function PremiumModal({ onClose }) {
  const [selectedOption, setSelectedOption] = useState(0)
  const [tonConnectUI, setOptions] = useTonConnectUI();

  const handleSelectOption = (index) => {
    TelegramAPI.Vibrate('heavy')
    setSelectedOption(index)
  }

  return (
    <div className={styles.premiumModal}>
      <div className={styles.headerImage}>
        <div className={styles.blur}></div>
        <div className={styles.iconHolder}>
          <PremiumIconBg className={styles.iconBG} />
          <PremiumIconContent className={styles.iconContent} />

          <PremiumIconOutline className={`${styles.outline_l_1} ${styles.outline_premium_icon}`} />
          <PremiumIconOutline className={`${styles.outline_l_2} ${styles.outline_premium_icon}`} />
          <PremiumIconOutline className={`${styles.outline_l_3} ${styles.outline_premium_icon}`} />
          <PremiumIconOutline className={`${styles.outline_l_4} ${styles.outline_premium_icon}`} />

          <PremiumIconOutline className={`${styles.outline_r_1} ${styles.outline_premium_icon}`} />
          <PremiumIconOutline className={`${styles.outline_r_2} ${styles.outline_premium_icon}`} />
          <PremiumIconOutline className={`${styles.outline_r_3} ${styles.outline_premium_icon}`} />
          <PremiumIconOutline className={`${styles.outline_r_4} ${styles.outline_premium_icon}`} />
        </div>
      </div>
      <div className={styles.title}>Mining Premium</div>
      <div className={styles.description}>Purchase mining premium for boost your income by 25%</div>

      <div className={styles.main}>
        <div className={styles.premiumModal_options}>
          {prices.map((item, index) => (
            <PremiumOption
              option={item}
              key={index}
              selected={selectedOption === index ? true : false}
              handleClick={handleSelectOption}
              index={index}
            />
          ))}
        </div>

        <div className={styles.buttons}>
          <PrimaryButton onClick={() => tonConnectUI.sendTransaction(myTransaction)}>Upgrade to premium</PrimaryButton>
          <PrimaryButton className={styles.backButton} onClick={onClose}>
            Back
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

const PremiumOption = ({ option, selected, handleClick, index }) => {
  return (
    <div
      className={`${styles.premiumModal_option} ${selected ? styles.selected : ''}`}
      onClick={() => handleClick(index)}>
      <div className={styles.left}>
        <div className={styles.select}></div>
        <p className={styles.name}>{option.term}</p>
      </div>
      <div className={styles.price}>{option.price} TON </div>
    </div>
  )
}
