import { BalanceCoinIcon } from '#/assets/icons'
import styles from './styles.module.css'
import { useUser } from '#/context/userContext'
import { formatNumber } from '#/services/helpers'

const StakingCard = ({ handleClick, isModal = false, containerStyle, stakeOption, receiveAmount }) => {
  const { user } = useUser()
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={`${styles.logoContainer} ${isModal && styles.modalLogoContainer}`}>
        <BalanceCoinIcon />
      </div>

      <div className={styles.right}>
        <div className={styles.options}>
          <div className={styles.option}>
            <div className={styles.header}>Token</div>
            <div className={styles.data}>{stakeOption.coin}</div>
          </div>

          <div className={styles.option}>
            <div className={styles.header}>APR</div>
            <div className={styles.data}>{stakeOption.arp}%</div>
          </div>

          <div className={styles.option}>
            <div className={styles.header}>Period</div>
            <div className={styles.data}>{stakeOption.days}d</div>
          </div>

          {!isModal && (
            <div className={styles.button} onClick={() => handleClick()}>
              Stake
            </div>
          )}
        </div>

        {isModal ? (
          <div className={styles.coinReceive}>
            <div className={styles.title}>You will receive</div>
            <div className={styles.amount}>
              {receiveAmount * (stakeOption.arp / 100)} <span>$Minex</span>
            </div>
          </div>
        ) : (
          <div className={styles.coinBalance}>
            <span>Your balance:</span> {formatNumber(user?.user?.balance ? user?.user?.balance : 0)} $MineX
          </div>
        )}
      </div>
    </div>
  )
}

export default StakingCard
