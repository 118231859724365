import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { GpuIcon, LinkIcon, MiningSpeedIcon, StatsIcon, TonOutline, WorkerIcon, WorkersIcon } from '#/assets/icons'
import { Link } from 'react-router-dom'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import LineChart from '#/pages/HomePage/components/Charts/LinkCard'
import TelegramAPI from '#/telegram'
import { formatNumber } from '#/services/helpers'
import LinkButton from '#/components/buttons/LinkButton'
import API from '#/api/api'
import CustomSkeleton from '#/components/loaders/CustomSkeleton'


export default function MinexStatsPage() {
  const [minexData, setMinexData] = useState([])
  const fetchMinexData = async () => {
    try {
      const result = await API.system.getMinexData()
      if (result.data) {
        setMinexData(result.data)
      }
    } catch (error) {
      console.log('Error with Minex data loading')
    }
  }

  useEffect(() => {
    fetchMinexData()
  }, [])

  return (
    <div className={styles.main}>
      <div className={styles.titleBar}>
        <StatsIcon className={styles.minexDataIcon} />
        <div className={styles.title}>Minex data</div>
      </div>

      <div className={styles.prices}>
        <div className={styles.priceLine}>
          <div className={styles.icon}>
            <TonOutline />
          </div>
          <div className={styles.price}>{minexData?.priceTon || <CustomSkeleton height={14} width={80} />}</div>
        </div>
        <div className={styles.priceLine}>
          <div className={styles.icon}>$</div>{' '}
          <div className={styles.price}>{minexData?.priceUsd || <CustomSkeleton height={14} width={80} />}</div>
        </div>
        <div className={styles.chart}>
          <LineChart dataPoints={minexData.points || []} />
        </div>
      </div>

      <div className={styles.progressesContainer}>
        <div className={styles.progressGroup}>
          <div className={styles.title}>MINED - {minexData.minedPercent}%</div>
          <div className={styles.progressbar}>
            {minexData.minedPercent ? (
              <div
                className={styles.progress}
                style={{ width: `${minexData.minedPercent}%`, background: '#FF4400' }}></div>
            ) : (

              <CustomSkeleton height={100} width={100} percents={true} />
            )}
          </div>
        </div>
        <div className={styles.progressGroup}>
          <div className={styles.title}>BURNED - {minexData.burnedPercent}%</div>
          <div className={styles.progressbar}>
          {minexData.burnedPercent ? (
              <div
                className={styles.progress}
                style={{ width: `${minexData.burnedPercent}%`, background: '#A7A7A7' }}></div>
            ) : (
              <CustomSkeleton height={100} width={100} percents={true} />
            )}

          </div>
        </div>
      </div>

      <div className={styles.minexData}>
        <div className={styles.leftColumn}>
          <div className={styles.minersData}>
            <div className={styles.title}>
              <div className={styles.icon}>
                <WorkerIcon />
              </div>
              <div className={styles.text}>MINERS</div>
            </div>
            <div className={styles.value}>
              {minexData?.miners ? (
                formatNumber(minexData?.miners, 'balance')
              ) : (
                <CustomSkeleton height={28} width={75} />
              )}
            </div>
          </div>

          <div className={styles.poolsData}>
            <div className={styles.title}>
              <div className={styles.icon}>
                <WorkersIcon />
              </div>
              <div className={styles.text}>POOLS</div>
            </div>
            <div className={styles.value}>
              {minexData?.pools ? formatNumber(minexData?.pools, 'balance') : <CustomSkeleton height={28} width={75} />}
            </div>
          </div>
        </div>

        <div className={styles.rightColumn}>
          <div className={styles.gpuData}>
            <div className={styles.title}>
              <div className={styles.icon}>
                <GpuIcon />
              </div>
              <div className={styles.text}>GPU</div>
            </div>
            <div className={styles.value}>
              {minexData?.gpu ? formatNumber(minexData?.gpu, 'balance') : <CustomSkeleton height={28} width={75} />}
            </div>
          </div>

          <div className={styles.separator}></div>

          <div className={styles.thData}>
            <div className={styles.title}>
              <div className={styles.icon}>
                <MiningSpeedIcon />
              </div>
              <div className={styles.text}>TH/S</div>
            </div>
            <div className={styles.value}>
              {minexData?.ths ? formatNumber(minexData?.ths, 'balance') : <CustomSkeleton height={28} width={75} />}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.linksHeader}>Links</div>
      <div className={styles.linksContainer}>
        <LinkButton
          title={'News EN'}
          icon={<LinkIcon />}
          onClick={() => TelegramAPI.OpenTelegramLink('https://t.me/minex_en')}
        />
        <LinkButton
          title={'News RU'}
          icon={<LinkIcon />}
          onClick={() => TelegramAPI.OpenTelegramLink('https://t.me/minex_ru')}
        />
        <LinkButton
          title={'Chat EN'}
          icon={<LinkIcon />}
          onClick={() => TelegramAPI.OpenTelegramLink('https://t.me/minex_enchat')}
        />
        <LinkButton
          title={'Chat RU'}
          icon={<LinkIcon />}
          onClick={() => TelegramAPI.OpenTelegramLink('https://t.me/minex_ruchat')}
        />
        <LinkButton
          title={'Tutorial'}
          icon={<LinkIcon />}
          onClick={() => TelegramAPI.OpenLink('https://minex.cx/hello/')}
        />
        <LinkButton
          title={'Docs'}
          icon={<LinkIcon />}
          onClick={() => TelegramAPI.OpenLink('https://minex.cx/hello/docs.html')}
        />
      </div>

      <div className={styles.buttonsContainer}>
        <Link to={'/profile'} className={styles.backLink}>
          <PrimaryButton className={styles.primaryButton}>Back</PrimaryButton>
        </Link>
      </div>
    </div>
  )
}
