import React from 'react'
import styles from './styles.module.css'
import TelegramAPI from '#/telegram'

const LinkButton = ({ icon, title, onClick }) => {
  const handleClick = () => {
    TelegramAPI.Vibrate('heavy')
    onClick()
  }
  return (
    <div className={styles.linkContainer} onClick={handleClick}>
      <div className={styles.linkIconContainer}>{icon}</div>
      <div className={styles.linkTitle}>{title}</div>
    </div>
  )
}

export default LinkButton
